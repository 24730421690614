<template>
  <label
    :class="[
      'v-radio',
      {
        'v-radio--focused': focus,
        'v-radio--disabled': disabled,
        'v-radio--active': isActive,
        'v-radio--outlined': outlined
      }
    ]"
    role="radio"
    :aria-checked="isActive"
    :aria-disabled="disabled"
    :tabindex="disabled ? -1 : 0"
    @keydown.enter="model = value"
    v-on="inheritListeners"
  >
    <span class="v-radio__body">
      <slot />
    </span>
    <input
      v-model="model"
      class="v-radio__original-radio"
      :value="value"
      type="radio"
      :tabindex="-1"
      :disabled="disabled"
      @focus="focus = true"
      @blur="focus = false"
    />
  </label>
</template>

<script>
import { isEqual } from '@/utils/common'

export default {
  name: 'VRadio',
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  props: {
    modelValue: { type: [String, Number, Object, Boolean], default: '' },
    value: { type: [String, Number, Object, Boolean], required: true },
    disabled: { type: Boolean, default: false },
    outlined: { type: Boolean, default: false }
  },
  data() {
    return {
      focus: false
    }
  },
  computed: {
    isActive() {
      return isEqual(this.value, this.modelValue)
    },
    model: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },
    inheritListeners() {
      const { 'update:modelValue': _update, ...restListeners } = this.$listeners
      return restListeners
    }
  }
}
</script>
