<template>
  <div class="agents-select">
    <label
      v-for="(agent, index) in options"
      :key="index"
      :class="[
        'responsible-agent-item',
        {
          'responsible-agent-item--selected': !$scopedSlots.prepend && !$scopedSlots.append && isSelected(agent)
        }
      ]"
      :role="multiple ? 'checkbox' : 'radio'"
      :aria-checked="isSelected(agent)"
    >
      <slot name="prepend" :isSelected="isSelected(agent)"> </slot>

      <input
        v-if="multiple"
        v-model="selectedAgents"
        :value="agent.id"
        class="responsible-agent-item__selector"
        type="checkbox"
      />
      <input v-else v-model="selectedAgent" :value="agent.id" class="responsible-agent-item__selector" type="radio" />

      <span class="responsible-agent-item__append">
        <slot name="append" v-bind="{ isSelected: isSelected(agent) }"> </slot>
      </span>
      <div class="responsible-agent-item__agent">
        <div class="responsible-agent-item__agent-photo">
          <v-avatar :src="agent.photo" />
        </div>
        <div class="responsible-agent-item__agent-name">{{ agent.name }}</div>
      </div>
    </label>
  </div>
</template>

<script>
import VAvatar from '@/components/common/VAvatar.vue'

export default {
  name: 'AgentsSelect',
  components: { VAvatar },
  props: {
    value: {
      type: [Array, Object],
      default: null
    },
    options: {
      type: Array,
      required: true
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    selectedAgent: {
      get() {
        return this.value?.id ?? null
      },
      set(agentId) {
        this.$emit(
          'input',
          this.options.find(option => option.id === agentId)
        )
      }
    },
    selectedAgents: {
      get() {
        if (Array.isArray(this.value)) {
          return this.value.map(agent => agent.id)
        }
        return this.value.id
      },
      set(agentsIds) {
        this.$emit(
          'input',
          this.options.filter(option => agentsIds.includes(option.id))
        )
      }
    }
  },
  methods: {
    isSelected({ id }) {
      return this.multiple ? this.selectedAgents.includes(id) : this.selectedAgent === id
    }
  }
}
</script>

<style lang="scss">
@import '../../assets/scss/media';
@import '../../assets/scss/mixins';

.responsible-agent-item {
  position: relative;
  cursor: pointer;
  $this: &;

  padding: 16px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $--semi-light-gray;

  &--selected {
    @include themify() {
      background-color: rgba(themed('sidebarUser'), 0.05);
    }
  }

  @include phone {
    padding: 16px 24px;
  }

  &__selector {
    @include visually-hidden;
  }

  &__append {
    position: absolute;
    right: 26px;
  }

  &__agent {
    display: flex;
    align-items: center;
  }

  &__agent-photo {
    margin-right: 16px;
  }

  &__agent-name {
    font-size: $--font-size-medium;
  }
}
</style>
