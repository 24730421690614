<template>
  <v-form class="searchrow" @submit.prevent="search">
    <v-input :value="localValue" v-bind="$attrs" @input="updateValue" v-on="inheritedListeners">
      <template #prepend>
        <button class="searchrow__button searchrow__button--search" type="submit">
          <v-icon-search />
        </button>
      </template>
      <template v-if="localValue && localValue.length" #append>
        <button class="searchrow__button searchrow__button--reset" type="button" @click="reset">
          <v-icon-cross class="searchrow__icon-cross" />
        </button>
      </template>
    </v-input>
  </v-form>
</template>

<script>
import VInput from '@/components/common/VInput.vue'
import VIconSearch from '@/components/icons/VSearch.vue'
import VIconCross from '@/components/icons/VCross.vue'
import VForm from '@/components/form/VForm.vue'

export default {
  name: 'VSearchRow',
  components: {
    VInput,
    VIconSearch,
    VIconCross,
    VForm
  },
  inheritAttrs: false,
  props: {
    value: { type: String, default: '' }
  },
  data() {
    return {
      localValue: this.value
    }
  },
  computed: {
    inheritedListeners() {
      const { search, input, ...restListeners } = this.$listeners
      return restListeners
    }
  },
  watch: {
    value(val) {
      this.localValue = val
    }
  },
  methods: {
    updateValue(value) {
      this.localValue = value
      this.$emit('input', value)
    },
    search() {
      this.$emit('search', this.localValue)
    },
    reset() {
      this.$emit('reset')
      this.updateValue('')
      this.search()
    }
  }
}
</script>
