<template>
  <fieldset-wrapper :model="model" :type="$options.SECTION_TYPE" :module="$options.MODULE_VILLAGES">
    <v-section title="SEO" class="village-edit-seo">
      <v-form-row>
        <v-form-field
          v-slot="{ validationErrors }"
          label="SEO ЗАГОЛОВОК"
          class="village-edit-seo__field village-edit-seo__field--text"
          rules="required"
        >
          <v-input v-model="model.seoTitle" :is-error="!!validationErrors.length" />
        </v-form-field>
      </v-form-row>

      <v-form-row>
        <v-form-field v-slot="{ validationErrors }" label="SEO ОПИСАНИЕ" rules="required">
          <v-textarea v-model="model.seoDescription" :is-error="!!validationErrors.length" />
        </v-form-field>
      </v-form-row>
    </v-section>
  </fieldset-wrapper>
</template>

<script>
import FieldsetWrapper from '@/components/form/FieldsetWrapper.vue'
import VSection from '@/components/common/VSection.vue'
import VFormRow from '@/components/form/VFormRow.vue'
import VFormField from '@/components/form/VFormField.vue'
import VInput from '@/components/common/VInput.vue'
import VTextarea from '@/components/common/VTextarea.vue'

import { SEO_INFO, MODULE_VILLAGES } from '@/store/modules/villages/villages.types'
import { cloneDeep } from '@/utils/common'
import { mapState } from 'vuex'

const SECTION_TYPE = SEO_INFO

export default {
  name: 'VillageEditSeo',
  components: { FieldsetWrapper, VSection, VFormField, VFormRow, VInput, VTextarea },
  SECTION_TYPE,
  MODULE_VILLAGES,
  data() {
    return {
      model: {}
    }
  },
  computed: {
    ...mapState(MODULE_VILLAGES, {
      stateModel: state => state[SECTION_TYPE]
    })
  },
  created() {
    this.model = cloneDeep(this.stateModel)
  }
}
</script>
