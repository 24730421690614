<template>
  <fieldset-wrapper :model="model" :type="$options.SECTION_TYPE" :module="$options.MODULE_VILLAGES">
    <v-section title="ОТВЕТСТВЕННЫЙ АГЕНТ" class="village-edit-responsible-agent">
      <template #header-append>
        <v-searchrow v-model="search" class="village-edit-responsible-agent__searchrow" />
      </template>
      <validation-provider rules="required">
        <agents-select v-model="agents" :options="filteredAgents" multiple>
          <template #prepend="{ isSelected }">
            <div class="village-edit-responsible-agent__agent-select-icon-wrapper">
              <v-tick v-if="isSelected" class="village-edit-responsible-agent__agent-select-icon" />
            </div>
          </template>
        </agents-select>
      </validation-provider>
    </v-section>
  </fieldset-wrapper>
</template>

<script>
import FieldsetWrapper from '@/components/form/FieldsetWrapper.vue'
import VSection from '@/components/common/VSection.vue'
import VSearchrow from '@/components/common/VSearchrow.vue'
import AgentsSelect from '@/components/Agent/AgentsSelect.vue'
import VTick from '@/components/icons/VTick.vue'

import { agentsService } from '@/services/http'
import { RESPONSIBLE_AGENTS, MODULE_VILLAGES } from '@/store/modules/villages/villages.types'
import { cloneDeep } from '@/utils/common'
import { mapState } from 'vuex'

const SECTION_TYPE = RESPONSIBLE_AGENTS

export default {
  name: 'VillageEditResponsibleAgent',
  components: { AgentsSelect, FieldsetWrapper, VSection, VSearchrow, VTick },
  SECTION_TYPE,
  MODULE_VILLAGES,
  data() {
    return {
      model: {},
      search: '',
      agentOptions: [],
      loading: false
    }
  },
  computed: {
    ...mapState(MODULE_VILLAGES, {
      stateModel: state => state[SECTION_TYPE]
    }),
    agents: {
      get() {
        return this.agentOptions.filter(agent => this.model.agents.includes(agent.id))
      },
      set(newAgents) {
        this.model.agents = newAgents.map(agent => agent.id)
      }
    },
    filteredAgents() {
      return this.agentOptions.filter(agent => agent.name.toLowerCase().includes(this.search.toLowerCase()))
    }
  },
  created() {
    this.model = cloneDeep(this.stateModel)

    this.loading = true
    agentsService
      .getList()
      .then(({ results }) => {
        this.agentOptions = results
      })
      .finally(() => {
        this.loading = false
      })
  }
}
</script>
