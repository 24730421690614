<template>
  <div class="v-map">
    <v-form-field class="v-map__search" label="ВВЕДИТЕ АДРЕС" separate-label rules="required">
      <v-input id="ymapSearch" v-model="search" :is-error="isError" v-on="inheritListeners">
        <template v-if="search && search.length" #append>
          <button class="v-map__button-clear" type="button" @click="reset">
            <v-icon-cross />
          </button>
        </template>
      </v-input>
      <v-form-error v-if="isError">{{ errors[0] }}</v-form-error>
    </v-form-field>
    <div class="v-map__wrapper">
      <yandex-map
        ref="mapContainer"
        :settings="$options.settings"
        :coords="localCoords"
        class="v-map__container"
        :controls="[]"
        :zoom="zoom"
        @click="onClick"
        @map-was-initialized="setMap"
      >
        <ymap-marker :coords="localCoords" marker-id="1" :icon="customMarker" />
      </yandex-map>
    </div>
  </div>
</template>

<script>
import { yandexMap, ymapMarker } from 'vue-yandex-maps'
import VInput from '@/components/common/VInput.vue'
import VIconCross from '@/components/icons/VCross.vue'
import VFormError from '@/components/form/VFormError.vue'
import config from '@/config'
import { mapGetters } from 'vuex'
import { MODULE_SESSION } from '@/store/modules/session/session.types'
import VFormField from '@/components/form/VFormField.vue'

const DEFAULT_MOSCOW_COORDS = Object.freeze([55.7503535552648, 37.61581057094254])

export default {
  name: 'VMap',
  settings: config.yandexMapConfig,
  components: { VFormField, VFormError, yandexMap, ymapMarker, VInput, VIconCross },
  props: {
    zoom: { type: Number, default: 10 },
    value: { type: Array, default: () => [] },
    isError: { type: Boolean, default: false },
    errors: { type: Array, default: () => [] },
    address: { type: String, default: '' }
  },
  data() {
    return {
      search: '',
      map: null,
      suggester: null,
      isClearCoords: false
    }
  },
  computed: {
    customMarker() {
      return {
        layout: 'default#imageWithContent',
        imageHref: '',
        imageSize: [22, 30],
        contentOffset: [-11, -30],
        contentLayout: `<svg width="22" height="30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22 11c0-6.1-5-11-11-11S0 4.9 0 11c0 6.3 6.6 14.8 9.6 18.3.7 1 2 1 2.8 0 3-3.5 9.6-12 9.6-18.4z"
      fill="${this.isNmh ? '#F6BA20' : '#1E78C3'}"
    />
    <path d="M5.5 10.5C5.5 7.5 8 5 11 5s5.5 2.5 5.5 5.5a5.5 5.5 0 11-11 0z" fill="#fff" />
  </svg>`
      }
    },
    ...mapGetters({
      isNmh: `${MODULE_SESSION}/isNmh`
    }),
    inheritListeners() {
      const { input: _input, ...restListeners } = this.$listeners
      return restListeners
    },
    localCoords: {
      get() {
        return this.value && this.value.length ? this.value : DEFAULT_MOSCOW_COORDS
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },

  watch: {
    search: {
      handler(val) {
        this.$emit('update:address', val)
        if (this.isClearCoords) {
          if (!val) this.localCoords = []
        }
      }
    }
  },
  created() {
    this.search = this.address
  },
  beforeDestroy() {
    if (this.suggester) this.suggester.destroy()
  },
  methods: {
    onClick(e) {
      const coords = e.get('coords')
      this.localCoords = coords
      this.setAddress(coords)
    },
    setAddress(coords) {
      // eslint-disable-next-line no-undef
      ymaps.geocode(coords).then(result => {
        this.search = result.geoObjects.get(0).getAddressLine()
      })
    },
    reset() {
      this.search = ''
    },
    setMap(map) {
      // eslint-disable-next-line no-undef
      this.suggester = new ymaps.SuggestView('ymapSearch')
      this.map = map

      this.suggester.events.add('select', event => {
        this.search = event.originalEvent.item.displayName
        this.getGeo()
      })

      if (!this.address && this.value?.length) {
        this.setAddress(this.value)
      }
      this.isClearCoords = !this.isClearCoords
    },
    getGeo() {
      // eslint-disable-next-line no-undef
      ymaps.geocode(this.search).then(res => {
        const obj = res.geoObjects.get(0)
        const bounds = obj.properties.get('boundedBy')
        const { width, height } = this.$refs.mapContainer.$el.getBoundingClientRect()
        // eslint-disable-next-line no-undef
        const mapState = ymaps.util.bounds.getCenterAndZoom(bounds, [width, height])

        this.map.setZoom(mapState.zoom)
        this.localCoords = mapState.center
      })
    }
  }
}
</script>
