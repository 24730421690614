<template>
  <match-media v-slot="{ mobile }">
    <fieldset-wrapper :model="model" :type="$options.SECTION_TYPE" :module="$options.MODULE_VILLAGES">
      <v-section title="Основная информация" class="village-edit-basic">
        <div class="village-edit-basic__inner">
          <v-form-row>
            <v-form-field label="ОТОБРАЖЕНИЕ" separate-label>
              <div class="village-edit-basic__field village-edit-basic__field--checkbox">
                <v-checkbox v-model="model.isSpecial">Главная страница</v-checkbox>
                <v-checkbox v-model="isPublic" :disabled="isPublicDisabled">Опубликовано</v-checkbox>
              </div>
            </v-form-field>
          </v-form-row>

          <v-form-row>
            <v-form-field label="№ (ID)" class="village-edit-basic__field village-edit-basic__field--text">
              <v-input :value="id" disabled />
            </v-form-field>
          </v-form-row>

          <v-form-row>
            <v-form-field label="ID CIAN" class="village-edit-basic__field village-edit-basic__field--text">
              <v-input v-model="model.cianId" type="number" />
            </v-form-field>
          </v-form-row>

          <v-form-row>
            <v-form-field label="КЛАСС ПОСЕЛКА" separate-label>
              <div class="village-edit-basic__field village-edit-basic__field--radio">
                <v-radio v-model="model.type" value="comfort">Комфорт-класс</v-radio>
                <v-radio v-model="model.type" value="business">Бизнес-класс</v-radio>
                <v-radio v-model="model.type" value="premium">Премиум-класс</v-radio>
                <v-radio v-model="model.type" value="deluxe">Делюкс-класс</v-radio>
              </div>
            </v-form-field>
          </v-form-row>

          <v-form-row>
            <v-form-field
              v-slot="{ validationErrors }"
              label="НАЗВАНИЕ"
              class="village-edit-basic__field village-edit-basic__field--text"
              rules="required"
            >
              <v-input v-model="model.name" :is-error="!!validationErrors.length" />
            </v-form-field>
          </v-form-row>

          <v-form-row>
            <v-form-field label="ВНУТРЕННЯЯ ИНФРАСТРУКТУРА (ТЭГИ)" separate-label>
              <v-select
                v-model="model.internalInfrastructureTags"
                multiple
                :options="internalInfrastructureOptions"
                :loading="internalInfrastructureLoading"
                label="name"
                :close-on-select="false"
                :reduce="tag => tag.id"
              />
            </v-form-field>
          </v-form-row>

          <v-form-row>
            <v-form-field label="ВНЕШНЯЯ ИНФРАСТРУКТУРА (ТЭГИ)" separate-label>
              <v-select
                v-model="model.externalInfrastructureTags"
                multiple
                :options="externalInfrastructureOptions"
                :loading="externalInfrastructureLoading"
                label="name"
                :close-on-select="false"
                :reduce="tag => tag.id"
              />
            </v-form-field>
          </v-form-row>

          <v-form-row flex class="village-edit-basic__row village-edit-basic__row--flex village-edit-basic__row--end">
            <v-form-field label="ГОД ПОСТРОЙКИ" class="village-edit-basic__field village-edit-basic__field--number">
              <v-input v-model="model.year" :tabindex="mobile ? 1 : 0" type="number" />
            </v-form-field>
            <v-form-field label="ТЕРРИТОРИЯ (ГА)" class="village-edit-basic__field village-edit-basic__field--number">
              <v-input v-model="model.territory" :tabindex="mobile ? 3 : 0" type="number" />
            </v-form-field>
            <v-form-field label="КОЛ-ВО УЧАСТКОВ" class="village-edit-basic__field village-edit-basic__field--number">
              <v-input v-model="model.plotsCount" :tabindex="mobile ? 4 : 0" type="number" />
            </v-form-field>
            <v-form-field
              :label="mobile ? '% ЗАСЕЛЕНИЯ' : 'ПРОЦЕНТ ЗАСЕЛЕНИЯ'"
              class="village-edit-basic__field village-edit-basic__field--number"
              type="number"
            >
              <v-input v-model="model.populationPercent" :tabindex="mobile ? 2 : 0" />
            </v-form-field>
          </v-form-row>

          <v-form-row class="village-edit-basic__row village-edit-basic__row--last">
            <v-form-field label="КОММУНИКАЦИИ" separate-label>
              <div class="village-edit-basic__field village-edit-basic__field--checkbox">
                <v-checkbox v-model="model.isGas">Газ</v-checkbox>
                <v-checkbox v-model="model.isElectricity">Электричество</v-checkbox>
                <v-checkbox v-model="model.isSewerage">Канализация</v-checkbox>
                <v-checkbox v-model="model.isWater">Водоснабжение</v-checkbox>
              </div>
            </v-form-field>
          </v-form-row>

          <v-form-row class="village-edit-basic__row village-edit-basic__row--youtube">
            <v-form-field label="ССЫЛКА YOUTUBE" class="village-edit-basic__field village-edit-basic__field--text">
              <v-input v-model="model.youtubeUrl" />
            </v-form-field>
          </v-form-row>
        </div>
      </v-section>
    </fieldset-wrapper>
  </match-media>
</template>

<script>
import { MatchMedia } from 'vue-component-media-queries'
import FieldsetWrapper from '@/components/form/FieldsetWrapper.vue'
import VFormField from '@/components/form/VFormField.vue'
import VFormRow from '@/components/form/VFormRow.vue'
import VSection from '@/components/common/VSection.vue'
import VRadio from '@/components/common/VRadio.vue'
import VCheckbox from '@/components/common/VCheckbox.vue'
import VInput from '@/components/common/VInput.vue'
import VSelect from '@/components/common/VSelect.vue'

import { BASIC_INFO, MODULE_VILLAGES } from '@/store/modules/villages/villages.types'
import { VILLAGE_ACTIVE_STATUS, VILLAGE_ARCHIVE_STATUS, VILLAGE_DRAFT_STATUS } from '@/constants/statuses/village'

import { cloneDeep } from '@/utils/common'
import { mapState } from 'vuex'
import { villagesService } from '@/services/http'

const SECTION_TYPE = BASIC_INFO

export default {
  name: 'VillageEditBasic',
  components: {
    FieldsetWrapper,
    VSection,
    VFormField,
    VFormRow,
    VRadio,
    VCheckbox,
    VInput,
    VSelect,
    MatchMedia
  },
  SECTION_TYPE,
  MODULE_VILLAGES,
  data() {
    return {
      model: {},

      internalInfrastructureOptions: [],
      externalInfrastructureOptions: [],
      internalInfrastructureLoading: false,
      externalInfrastructureLoading: false
    }
  },
  computed: {
    ...mapState(MODULE_VILLAGES, {
      stateModel: state => state[SECTION_TYPE],
      id: state => state.id
    }),

    isPublic: {
      get() {
        return this.model.status === VILLAGE_ACTIVE_STATUS
      },
      set(status) {
        this.model.status = status ? VILLAGE_ACTIVE_STATUS : VILLAGE_ARCHIVE_STATUS
      }
    },
    isDraft() {
      return this.model.status === VILLAGE_DRAFT_STATUS
    },
    isPublicDisabled() {
      return !this.id || this.isDraft
    }
  },
  created() {
    this.model = cloneDeep(this.stateModel)

    this.internalInfrastructureLoading = true
    villagesService
      .selectInternalInfrastructureTags()
      .then(res => {
        this.internalInfrastructureOptions = res
      })
      .finally(() => {
        this.internalInfrastructureLoading = false
      })

    this.externalInfrastructureLoading = false
    villagesService
      .selectExternalInfrastructureTags()
      .then(res => {
        this.externalInfrastructureOptions = res
      })
      .finally(() => {
        this.externalInfrastructureLoading = false
      })
  }
}
</script>
