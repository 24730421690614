<template>
  <match-media v-slot="{ mobile }" class="village-edit-general">
    <template v-if="!mobile">
      <edit-village-basic />
      <edit-village-description />
      <edit-village-seo />
    </template>

    <template v-else>
      <v-section v-if="!activeSection" title="Общее" class="village-edit-general__menu">
        <v-menu :value="activeSection" @input="handleInput">
          <v-menu-item name="basic">
            <span class="village-edit-general__menu-item">
              Основная информация
              <v-icon-tick v-if="isBasicTabFilled" />
            </span>
          </v-menu-item>
          <v-menu-item name="description">
            <span class="village-edit-general__menu-item">
              Описание
              <v-icon-tick v-if="isDescriptionTabFilled" />
            </span>
          </v-menu-item>
          <v-menu-item name="seo">
            <span class="village-edit-general__menu-item">
              SEO
              <v-icon-tick v-if="isSeoTabFilled" />
            </span>
          </v-menu-item>
        </v-menu>
      </v-section>

      <div class="village-edit-general__content">
        <edit-village-basic v-show="activeSection === 'basic'" />
        <edit-village-description v-show="activeSection === 'description'" />
        <edit-village-seo v-show="activeSection === 'seo'" />
      </div>
    </template>
  </match-media>
</template>

<script>
import { MatchMedia } from 'vue-component-media-queries'
import VSection from '@/components/common/VSection.vue'
import VMenu from '@/components/common/VMenu.vue'
import VMenuItem from '@/components/common/VMenuItem.vue'
import VIconTick from '@/components/icons/VTick.vue'
import EditVillageBasic from '@/components/Villages/Edit/Basic.vue'
import EditVillageDescription from '@/components/Villages/Edit/Description.vue'
import EditVillageSeo from '@/components/Villages/Edit/Seo.vue'

export default {
  name: 'VillageEditGeneral',
  components: {
    MatchMedia,
    VSection,
    VMenu,
    VMenuItem,
    VIconTick,
    EditVillageBasic,
    EditVillageDescription,
    EditVillageSeo
  },
  props: {
    activeSection: { type: [String, Object], default: () => null },
    isBasicTabFilled: { type: Boolean, default: false },
    isDescriptionTabFilled: { type: Boolean, default: false },
    isSeoTabFilled: { type: Boolean, default: false }
  },
  methods: {
    handleInput(val) {
      this.$emit('change-section', val)
    }
  }
}
</script>
