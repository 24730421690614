<template>
  <div class="v-file-edit">
    <div class="v-file-edit__index">{{ index }}</div>
    <div class="v-file-edit__filename">
      <span class="v-file-edit__name">{{ filename }}</span>
      <span class="v-file-edit__extension">{{ extension }}</span>
    </div>
    <div class="v-file-edit__name-input">
      <v-input v-model="customName" placeholder="Название файла" :disabled="loading" />
    </div>
    <div v-if="loading" class="v-file-edit__loading">{{ progressPercentage }}</div>
    <template v-else>
      <div class="v-file-edit__date">{{ formattedDate }}</div>
      <div class="v-file-edit__actions">
        <v-button-icon @click="remove">
          <v-icon-basket />
        </v-button-icon>
      </div>
    </template>
  </div>
</template>

<script>
import VInput from '@/components/common/VInput.vue'
import VButtonIcon from '@/components/common/VButtonIcon.vue'
import VIconBasket from '@/components/icons/VBasket.vue'
import { formatDate } from '@/utils/formatters'

export default {
  name: 'VFilePreview',
  components: { VInput, VButtonIcon, VIconBasket },
  props: {
    index: { type: Number, required: true },
    name: { type: String, required: true },
    createdAt: { type: [String, Number], required: true },
    progress: { type: Number, default: undefined }
  },
  data() {
    return {
      initialFileName: '',
      localFileName: ''
    }
  },
  computed: {
    customName: {
      get() {
        return this.localFileName
      },
      set(val) {
        this.localFileName = val
        this.$emit('rename', val || this.initialFileName)
      }
    },
    filename() {
      return this.name.split('.')[0]
    },
    extension() {
      return `.${this.name.split('.')[1]}`
    },
    formattedDate() {
      return formatDate(this.createdAt, '.')
    },
    loading() {
      return this.progress !== undefined
    },
    progressPercentage() {
      return `${Math.round(this.progress * 100)}%`
    }
  },
  created() {
    this.initialFileName = this.filename
  },
  methods: {
    remove() {
      this.$emit('remove')
    }
  }
}
</script>

<style lang="scss">
@import 'src/assets/scss/mixins';

.v-file-edit {
  display: flex;
  align-items: center;
  padding: 16px;
  background-color: $--darken-black-5percent;
  font-size: $--font-size-medium;

  &:not(:last-child) {
    border-bottom: 1px solid $--dark-gray;
  }

  &__index {
    width: 20px;
    margin-right: 8px;
    font-weight: $--font-weight-medium;

    @include linear-text;
  }

  &__filename {
    width: 100px;
    margin-right: 16px;
    display: flex;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: $--font-weight-medium;
  }

  &__name {
    flex-shrink: 1;

    @include linear-text;
  }

  &__extension {
    transform: translateX(-1px);
    flex-shrink: 0;
  }

  &__name-input {
    width: 320px;
    margin-right: 16px;
  }

  &__date {
    width: 130px;
    text-align: right;
    color: $--silver;
  }

  &__loading {
    color: $--silver;
    margin-left: auto;
  }

  &__actions {
    margin-left: auto;
  }
}
</style>
