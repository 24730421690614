<template>
  <svg class="icon icon--search" width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <!-- eslint-disable vue/max-len -->
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.3 11.7h.6l4.2 4.1-1.3 1.3-4.1-4.2v-.6l-.3-.3a5.4 5.4 0 11.6-.6l.3.3zM4.2 7.9a3.7 3.7 0 107.5 0 3.7 3.7 0 00-7.5 0z"
    />
  </svg>
</template>

<script>
export default {
  name: 'VSearch'
}
</script>
