<template>
  <match-media v-slot="{ mobile, desktop }">
    <validation-observer ref="commonObserver" tag="div" class="edit-village">
      <div class="edit-village__header">
        <v-breadcrumbs v-if="!mobile" :items="breadcrumbItems" class="edit-village__breadcrumbs" />

        <v-button-go-back v-else @click="goBack" />

        <v-page-title class="edit-village__title">{{ title }}</v-page-title>
        <div v-if="!mobile" class="edit-village__tabs-wrapper">
          <v-tabs v-model="activeSection" :primary="mobile">
            <v-tab name="general">
              <span v-if="isGeneralTabFilled" class="edit-village__tab-icon"><v-icon-tick /> </span>
              Общее
            </v-tab>
            <v-tab name="placement">
              <span v-if="validations.placementSectionValid" class="edit-village__tab-icon"><v-icon-tick /> </span>
              Расположение
            </v-tab>
            <v-tab name="photos">
              <span v-if="validations.photosSectionValid" class="edit-village__tab-icon"><v-icon-tick /> </span>
              Фотографии
            </v-tab>
            <v-tab v-if="desktop" name="files">
              <span v-if="validations.filesSectionValid" class="edit-village__tab-icon"><v-icon-tick /> </span>
              Файлы
            </v-tab>
            <v-tab name="agent">
              <span v-if="validations.agentsSectionValid" class="edit-village__tab-icon"><v-icon-tick /> </span>
              Ответственный агент
            </v-tab>
          </v-tabs>
        </div>
      </div>

      <div class="edit-village__content">
        <div v-if="mobile && !activeSection" class="edit-village__mobile-menu">
          <v-menu v-model="activeSection">
            <v-menu-item name="general">
              <span class="edit-village__menu-item">
                Общее
                <v-icon-tick v-if="isGeneralTabFilled" />
              </span>
            </v-menu-item>
            <v-menu-item name="placement">
              <span class="edit-village__menu-item">
                Расположение
                <v-icon-tick v-if="validations.placementSectionValid" />
              </span>
            </v-menu-item>
            <v-menu-item name="photos">
              <span class="edit-village__menu-item">
                Фотографии
                <v-icon-tick v-if="validations.photosSectionValid" />
              </span>
            </v-menu-item>
            <v-menu-item name="agent">
              <span class="edit-village__menu-item">
                Ответственный агент
                <v-icon-tick v-if="validations.agentsSectionValid" />
              </span>
            </v-menu-item>
          </v-menu>
        </div>

        <!-- sections -->
        <div v-if="!loading" class="edit-village__sections-wrapper">
          <village-edit-general
            v-show="activeSection === 'general'"
            :active-section="generalActiveSection"
            :is-basic-tab-filled="validations.basicSectionValid"
            :is-description-tab-filled="validations.descriptionSectionValid"
            :is-seo-tab-filled="validations.seoSectionValid"
            @change-section="generalActiveSection = $event"
          />

          <village-edit-placement v-show="activeSection === 'placement'" />

          <village-edit-responsible-agent v-show="activeSection === 'agent'" />

          <village-edit-photos v-show="activeSection === 'photos'" />

          <village-edit-files v-show="desktop && activeSection === 'files'" />
          <div v-if="!mobile || !activeSection" class="edit-village__actions">
            <template v-if="isCreation || isDraft">
              <v-button class="edit-village__action" :disabled="loading" @click="saveAsDraft"
                >Сохранить черновик</v-button
              >
              <v-button primary class="edit-village__action" :disabled="!isValidForSubmit || loading" @click="save"
                >Опубликовать</v-button
              >
            </template>
            <v-button v-else primary class="edit-village__action" :disabled="!isValidForSubmit || loading" @click="save"
              >Обновить</v-button
            >
          </div>
        </div>
      </div>
    </validation-observer>
  </match-media>
</template>

<script>
import { MatchMedia } from 'vue-component-media-queries'
import VBreadcrumbs from '@/components/common/VBreadcrumbs.vue'
import VPageTitle from '@/components/common/VPageTitle.vue'
import VTabs from '@/components/common/VTabs.vue'
import VTab from '@/components/common/VTab.vue'
import VMenu from '@/components/common/VMenu.vue'
import VMenuItem from '@/components/common/VMenuItem.vue'
import VIconTick from '@/components/icons/VTick.vue'
import VButtonGoBack from '@/components/common/VButtonGoBack.vue'
import VButton from '@/components/common/VButton.vue'
import VillageEditGeneral from '@/components/Villages/Edit/General.vue'
import VillageEditPlacement from '@/components/Villages/Edit/Placement.vue'
import VillageEditResponsibleAgent from '@/components/Villages/Edit/ResponsibleAgent.vue'
import VillageEditPhotos from '@/components/Villages/Edit/Photos.vue'
import VillageEditFiles from '@/components/Villages/Edit/Files.vue'
import {
  FETCH_VILLAGE,
  MODULE_VILLAGES,
  BASIC_INFO,
  DESCRIPTION_INFO,
  SEO_INFO,
  PLACEMENT_INFO,
  PHOTOS,
  FILES,
  RESPONSIBLE_AGENTS,
  RESET_STATE,
  CREATE_VILLAGE,
  UPDATE_VILLAGE,
  FULL_VILLAGE_MODEL
} from '@/store/modules/villages/villages.types'
import { mapActions, mapGetters, mapState } from 'vuex'
import { VILLAGE_ACTIVE_STATUS, VILLAGE_DRAFT_STATUS } from '@/constants/statuses/village'
import { loadingService } from '@/services/loading'
import noticeService from '@/services/notification'

export default {
  name: 'VillageEdit',
  components: {
    VButtonGoBack,
    VBreadcrumbs,
    VPageTitle,
    VTabs,
    VTab,
    VMenu,
    VMenuItem,
    MatchMedia,
    VIconTick,
    VillageEditGeneral,
    VillageEditPlacement,
    VillageEditResponsibleAgent,
    VillageEditPhotos,
    VillageEditFiles,
    VButton
  },
  data() {
    return {
      validations: {
        seoSectionValid: false,
        basicSectionValid: false,
        descriptionSectionValid: false,
        placementSectionValid: false,
        photosSectionValid: false,
        filesSectionValid: false,
        agentsSectionValid: false
      }
    }
  },
  inject: ['mediaQueries'],
  computed: {
    isGeneralTabFilled() {
      return (
        this.validations.basicSectionValid &&
        this.validations.descriptionSectionValid &&
        this.validations.seoSectionValid
      )
    },
    isValidForSubmit() {
      return this.isGeneralTabFilled && this.validations.placementSectionValid
    },
    activeSection: {
      get() {
        return this.$route.query.tab || null
      },
      set(val) {
        this.$router.push({ query: { tab: val } })
      }
    },
    generalActiveSection: {
      get() {
        return this.$route.query.general || null
      },
      set(val) {
        this.$router.push({ query: { ...this.$route.query, general: val } })
      }
    },

    villageId() {
      return this.$route.params.villageId
    },
    isCreation() {
      return !this.villageId
    },
    isDraft() {
      return this.villageModel.status === VILLAGE_DRAFT_STATUS
    },
    title() {
      return this.isCreation ? 'Новый коттеджный поселок' : 'Редактирование коттеджного поселка'
    },
    breadcrumbItems() {
      return [
        {
          text: 'Коттеджные поселки',
          to: { name: 'villages' }
        },
        {
          text: this.isCreation ? 'Новый' : 'Редактирование',
          disabled: true
        }
      ]
    },

    ...mapGetters(MODULE_VILLAGES, {
      villageModel: FULL_VILLAGE_MODEL
    }),
    ...mapState(MODULE_VILLAGES, {
      loading: state => state.loading
    })
  },
  watch: {
    loading(val) {
      loadingService.setViewLoading(val)
    }
  },
  created() {
    if (!this.activeSection && !this.mediaQueries.mobile) {
      this.$router.replace({ query: { tab: 'general' } })
    }

    if (!this.isCreation) {
      this.fetchVillage(this.villageId)
    }
  },
  mounted() {
    this.$watch(
      () => {
        return this.$refs.commonObserver.observers.find(obs => obs.vid === BASIC_INFO)?.flags.valid
      },
      isValid => {
        this.validations.basicSectionValid = isValid
      }
    )
    this.$watch(
      () => {
        return this.$refs.commonObserver.observers.find(obs => obs.vid === DESCRIPTION_INFO)?.flags.valid
      },
      isValid => {
        this.validations.descriptionSectionValid = isValid
      }
    )
    this.$watch(
      () => {
        return this.$refs.commonObserver.observers.find(obs => obs.vid === SEO_INFO)?.flags.valid
      },
      isValid => {
        this.validations.seoSectionValid = isValid
      }
    )
    this.$watch(
      () => {
        return this.$refs.commonObserver.observers.find(obs => obs.vid === PLACEMENT_INFO)?.flags.valid
      },
      isValid => {
        this.validations.placementSectionValid = isValid
      }
    )
    this.$watch(
      () => {
        return this.$refs.commonObserver.observers.find(obs => obs.vid === RESPONSIBLE_AGENTS)?.flags.valid
      },
      isValid => {
        this.validations.agentsSectionValid = isValid
      }
    )
    this.$watch(
      () => {
        return this.$refs.commonObserver.observers.find(obs => obs.vid === PHOTOS)?.flags.valid
      },
      isValid => {
        this.validations.photosSectionValid = isValid
      }
    )
    this.$watch(
      () => {
        return this.$refs.commonObserver.observers.find(obs => obs.vid === FILES)?.flags.valid
      },
      isValid => {
        this.validations.filesSectionValid = isValid
      }
    )
  },
  beforeDestroy() {
    this.resetState()
  },
  methods: {
    ...mapActions(MODULE_VILLAGES, {
      resetState: RESET_STATE,
      fetchVillage: FETCH_VILLAGE,
      createVillage: CREATE_VILLAGE,
      updateVillage: UPDATE_VILLAGE
    }),
    save() {
      this.localLoading = true

      if (this.isCreation) {
        this.createVillage({ ...this.villageModel, status: VILLAGE_ACTIVE_STATUS })
          .then(() => {
            noticeService.success('Коттеджный поселок опубликован!').then(() => {
              this.$router.push({ name: 'villages' })
            })
          })
          .catch(() => noticeService.error('Не удалось опубликовать коттеджный поселок'))
          .finally(() => {
            this.localLoading = false
          })
      } else {
        this.updateVillage({ ...this.villageModel, status: VILLAGE_ACTIVE_STATUS })
          .then(() => {
            noticeService.success('Коттеджный поселок обновлен!').then(() => {
              this.$router.push({ name: 'villages' })
            })
          })
          .catch(() => noticeService.error('Не удалось обновить коттеджный поселок!'))
          .finally(() => {
            this.localLoading = false
          })
      }
    },
    saveAsDraft() {
      this.localLoading = true

      if (this.isCreation) {
        this.createVillage({ ...this.villageModel, status: VILLAGE_DRAFT_STATUS })
          .then(() => {
            noticeService.success('Черновик сохранен!').then(() => {
              this.$router.push({ name: 'villages' })
            })
          })
          .catch(() => noticeService.error('Не удалось сохранить черновик!'))
          .finally(() => {
            this.localLoading = false
          })
      } else {
        this.updateVillage(this.villageModel)
          .then(() => {
            noticeService.success('Черновик обновлен!').then(() => {
              this.$router.push({ name: 'villages' })
            })
          })
          .catch(() => noticeService.error('Не удалось обновить черновик!'))
          .finally(() => {
            this.localLoading = false
          })
      }
    },
    goBack() {
      if (!this.activeSection) {
        this.$router.push({ name: 'villages' })
      } else if (this.generalActiveSection) {
        this.generalActiveSection = null
      } else if (this.activeSection) {
        this.activeSection = null
      }
    }
  }
}
</script>
