var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fieldset-wrapper',{attrs:{"model":_vm.model,"type":_vm.$options.SECTION_TYPE,"module":_vm.$options.MODULE_VILLAGES}},[_c('v-section',{staticClass:"village-edit-placement",attrs:{"title":"Расположение"}},[_c('v-form-row',[_c('v-map',{model:{value:(_vm.coordinatesArray),callback:function ($$v) {_vm.coordinatesArray=$$v},expression:"coordinatesArray"}})],1),_c('v-form-row',{staticClass:"village-edit-placement__row"},[_c('v-form-field',{staticClass:"village-edit-placement__field village-edit-placement__field--half-size",attrs:{"label":"КООРДИНАТЫ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-input',{attrs:{"is-error":!!validationErrors.length},model:{value:(_vm.model.coordinates),callback:function ($$v) {_vm.$set(_vm.model, "coordinates", $$v)},expression:"model.coordinates"}})]}}])}),_c('v-form-field',{staticClass:"village-edit-placement__field village-edit-placement__field--half-size",attrs:{"label":"НАЗВАНИЕ ШОССЕ","separate-label":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-select',{attrs:{"options":_vm.highwayOptions,"reduce":function (highway) { return highway.value; },"is-error":!!validationErrors.length},model:{value:(_vm.model.highway),callback:function ($$v) {_vm.$set(_vm.model, "highway", $$v)},expression:"model.highway"}})]}}])})],1),_c('v-form-row',{staticClass:"village-edit-placement__row"},[_c('v-form-field',{staticClass:"village-edit-placement__field village-edit-placement__field--half-size",attrs:{"label":"ПОСЕЛЕНИЕ","separate-label":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-select',{attrs:{"loading":_vm.areaLoading,"options":_vm.areaOptions,"reduce":function (area) { return area.id; },"label":"name","is-error":!!validationErrors.length},model:{value:(_vm.model.area),callback:function ($$v) {_vm.$set(_vm.model, "area", $$v)},expression:"model.area"}})]}}])}),_c('v-form-field',{staticClass:"village-edit-placement__field village-edit-placement__field--half-size",attrs:{"label":"НАСЕЛЕННЫЙ ПУНКТ","separate-label":""}},[_c('v-select',{attrs:{"loading":_vm.settlementLoading,"options":_vm.settlementOptions,"reduce":function (settlement) { return settlement.id; },"label":"name"},model:{value:(_vm.model.settlement),callback:function ($$v) {_vm.$set(_vm.model, "settlement", $$v)},expression:"model.settlement"}})],1)],1),_c('v-form-row',{staticClass:"village-edit-placement__row village-edit-placement__row--numbers"},[_c('v-form-field',{staticClass:"village-edit-placement__field village-edit-placement__field--number village-edit-placement__field--validatable",attrs:{"label":"КМ ОТ МКАД","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-input',{attrs:{"is-error":!!validationErrors.length,"type":"number"},model:{value:(_vm.model.distanceFromMkad),callback:function ($$v) {_vm.$set(_vm.model, "distanceFromMkad", $$v)},expression:"model.distanceFromMkad"}})]}}])}),_c('v-form-field',{staticClass:"village-edit-placement__field village-edit-placement__field--number",attrs:{"label":"КМ ОТ ШОССЕ"}},[_c('v-input',{attrs:{"type":"number"},model:{value:(_vm.model.distanceFromHighway),callback:function ($$v) {_vm.$set(_vm.model, "distanceFromHighway", $$v)},expression:"model.distanceFromHighway"}})],1),_c('v-form-field',{staticClass:"village-edit-placement__field village-edit-placement__field--number",attrs:{"label":"КМ ОТ МЕТРО"}},[_c('v-input',{attrs:{"type":"number"},model:{value:(_vm.model.distanceFromMetro),callback:function ($$v) {_vm.$set(_vm.model, "distanceFromMetro", $$v)},expression:"model.distanceFromMetro"}})],1),_c('v-form-field',{staticClass:"village-edit-placement__field village-edit-placement__field--number",attrs:{"label":"КМ ОТ ОСТАНОВКИ"}},[_c('v-input',{attrs:{"type":"number"},model:{value:(_vm.model.distanceFromBusStop),callback:function ($$v) {_vm.$set(_vm.model, "distanceFromBusStop", $$v)},expression:"model.distanceFromBusStop"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }