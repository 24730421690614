<template>
  <fieldset-wrapper :model="model" :type="$options.SECTION_TYPE" :module="$options.MODULE_VILLAGES">
    <v-section title="Описание" class="village-edit-description">
      <v-form-row>
        <v-form-field v-slot="{ validationErrors }" label="ТЕКСТ" rules="required">
          <v-textarea v-model="model.description" :is-error="!!validationErrors.length" />
        </v-form-field>
      </v-form-row>
    </v-section>
  </fieldset-wrapper>
</template>

<script>
import FieldsetWrapper from '@/components/form/FieldsetWrapper.vue'
import VSection from '@/components/common/VSection.vue'
import VFormRow from '@/components/form/VFormRow.vue'
import VFormField from '@/components/form/VFormField.vue'
import VTextarea from '@/components/common/VTextarea.vue'

import { DESCRIPTION_INFO, MODULE_VILLAGES } from '@/store/modules/villages/villages.types'
import { cloneDeep } from '@/utils/common'
import { mapState } from 'vuex'

const SECTION_TYPE = DESCRIPTION_INFO

export default {
  name: 'VillageEditDescription',
  components: { FieldsetWrapper, VSection, VFormField, VFormRow, VTextarea },
  SECTION_TYPE,
  MODULE_VILLAGES,
  data() {
    return {
      model: {}
    }
  },
  computed: {
    ...mapState(MODULE_VILLAGES, {
      stateModel: state => state[SECTION_TYPE]
    })
  },
  created() {
    this.model = cloneDeep(this.stateModel)
  }
}
</script>
