<template>
  <button type="button" class="v-button-icon" v-on="$listeners">
    <slot />
  </button>
</template>

<script>
export default {
  name: 'VButtonIcon'
}
</script>
