<template>
  <div class="v-file-uploader-with-preview">
    <v-input-file accept="*" class="v-file-uploader-with-preview__add" multiple @change="addFile">
      <slot name="fileInputLabel">Загрузить файл</slot>
    </v-input-file>

    <div class="v-file-uploader-with-preview__list">
      <slot name="filePreview" :previewFiles="localFiles">
        <v-file-preview
          v-for="(file, index) in localFiles"
          :key="index"
          :name="file.name"
          :created-at="file.createdAt"
          :progress="file.progress"
          :index="index + 1"
          @remove="removeFile(file)"
          @rename="renameFile(file, $event)"
        />
      </slot>
    </div>
  </div>
</template>

<script>
import VInputFile from '@/components/common/VInputFile.vue'
import VFilePreview from '@/components/common/VFilePreview.vue'
import { uploadingService } from '@/services/http'

export default {
  name: 'VFileUploaderWithPreview',
  components: { VInputFile, VFilePreview },
  props: {
    files: { type: Array, required: true }
  },
  data() {
    return {
      temporaryFiles: []
    }
  },
  computed: {
    localFiles() {
      return [...this.files, ...this.temporaryFiles]
    }
  },
  methods: {
    addFile(files) {
      files.forEach(file => {
        const tmpFile = { progress: 0, createdAt: Date.now(), name: file.name }
        this.temporaryFiles.push(tmpFile)

        uploadingService.uploadFile(file, this.getUpdateLoadingProgressCb(tmpFile)).then(({ id }) => {
          this.temporaryFiles = this.temporaryFiles.filter(f => f !== tmpFile)
          this.$emit('add', { id, name: tmpFile.name, createdAt: tmpFile.createdAt })
        })
      })
    },
    getUpdateLoadingProgressCb(file) {
      const tmpFile = this.temporaryFiles.find(f => f === file)

      return progressEvent => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader('content-length') ||
            progressEvent.target.getResponseHeader('x-decompressed-content-length')

        if (totalLength) {
          tmpFile.progress = progressEvent.loaded / totalLength
        }
      }
    },
    removeFile(file) {
      this.$emit('remove', file)
    },
    renameFile(file, name) {
      this.$emit('rename', file, name)
    }
  }
}
</script>

<style lang="scss">
.v-file-uploader-with-preview {
  &__add {
    width: 128px;
    margin-bottom: 24px;
  }

  &__list {
    border-radius: $--main-border-radius;
    overflow: hidden;
  }
}
</style>
