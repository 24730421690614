<template>
  <div :class="classes">
    <img v-if="hasAvatar" :src="src" alt="Avatar" class="avatar__img" />
    <div v-else class="avatar__default">
      <v-icon-avatar />
    </div>
  </div>
</template>

<script>
import VIconAvatar from '@/components/icons/VAvatar.vue'
import AVATAR_SIZES from '@/constants/avatarSize'

export default {
  name: 'VAvatar',
  components: {
    VIconAvatar
  },
  props: {
    src: { type: String, default: null },
    size: { type: String, default: 'medium', validate: val => AVATAR_SIZES.includes(val) }
  },
  computed: {
    classes() {
      return ['avatar', `avatar--${this.size}`]
    },
    hasAvatar() {
      return !!this.src
    }
  }
}
</script>
