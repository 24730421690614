<template>
  <fieldset-wrapper :model="model" :type="$options.SECTION_TYPE" :module="$options.MODULE_VILLAGES">
    <v-section title="Расположение" class="village-edit-placement">
      <v-form-row>
        <v-map v-model="coordinatesArray" />
      </v-form-row>

      <v-form-row class="village-edit-placement__row">
        <v-form-field
          v-slot="{ validationErrors }"
          label="КООРДИНАТЫ"
          class="village-edit-placement__field village-edit-placement__field--half-size"
          rules="required"
        >
          <v-input v-model="model.coordinates" :is-error="!!validationErrors.length" />
        </v-form-field>
        <v-form-field
          v-slot="{ validationErrors }"
          label="НАЗВАНИЕ ШОССЕ"
          separate-label
          class="village-edit-placement__field village-edit-placement__field--half-size"
          rules="required"
        >
          <v-select
            v-model="model.highway"
            :options="highwayOptions"
            :reduce="highway => highway.value"
            :is-error="!!validationErrors.length"
          />
        </v-form-field>
      </v-form-row>

      <v-form-row class="village-edit-placement__row">
        <v-form-field
          v-slot="{ validationErrors }"
          label="ПОСЕЛЕНИЕ"
          separate-label
          class="village-edit-placement__field village-edit-placement__field--half-size"
          rules="required"
        >
          <v-select
            v-model="model.area"
            :loading="areaLoading"
            :options="areaOptions"
            :reduce="area => area.id"
            label="name"
            :is-error="!!validationErrors.length"
          />
        </v-form-field>
        <v-form-field
          label="НАСЕЛЕННЫЙ ПУНКТ"
          separate-label
          class="village-edit-placement__field village-edit-placement__field--half-size"
        >
          <v-select
            v-model="model.settlement"
            :loading="settlementLoading"
            :options="settlementOptions"
            :reduce="settlement => settlement.id"
            label="name"
          />
        </v-form-field>
      </v-form-row>

      <v-form-row class="village-edit-placement__row village-edit-placement__row--numbers">
        <!-- eslint-disable vue/max-len -->
        <v-form-field
          v-slot="{ validationErrors }"
          label="КМ ОТ МКАД"
          class="village-edit-placement__field village-edit-placement__field--number village-edit-placement__field--validatable"
          rules="required"
        >
          <v-input v-model="model.distanceFromMkad" :is-error="!!validationErrors.length" type="number" />
        </v-form-field>
        <v-form-field label="КМ ОТ ШОССЕ" class="village-edit-placement__field village-edit-placement__field--number">
          <v-input v-model="model.distanceFromHighway" type="number" />
        </v-form-field>
        <v-form-field label="КМ ОТ МЕТРО" class="village-edit-placement__field village-edit-placement__field--number">
          <v-input v-model="model.distanceFromMetro" type="number" />
        </v-form-field>
        <v-form-field
          label="КМ ОТ ОСТАНОВКИ"
          class="village-edit-placement__field village-edit-placement__field--number"
        >
          <v-input v-model="model.distanceFromBusStop" type="number" />
        </v-form-field>
      </v-form-row>
    </v-section>
  </fieldset-wrapper>
</template>

<script>
import FieldsetWrapper from '@/components/form/FieldsetWrapper.vue'
import VFormField from '@/components/form/VFormField.vue'
import VFormRow from '@/components/form/VFormRow.vue'
import VSection from '@/components/common/VSection.vue'
import VMap from '@/components/common/VMap.vue'
import VInput from '@/components/common/VInput.vue'
import VSelect from '@/components/common/VSelect.vue'

import { PLACEMENT_INFO, MODULE_VILLAGES } from '@/store/modules/villages/villages.types'
import { cloneDeep } from '@/utils/common'
import { mapState } from 'vuex'
import { geoService } from '@/services/http'
import { HIGHWAY_OPTIONS } from '@/constants/objectRealtyOptions'

const SECTION_TYPE = PLACEMENT_INFO

export default {
  name: 'VillageEditPlacement',
  components: { FieldsetWrapper, VFormField, VFormRow, VSection, VMap, VInput, VSelect },
  SECTION_TYPE,
  MODULE_VILLAGES,
  data() {
    return {
      model: {},

      highwayOptions: HIGHWAY_OPTIONS,
      settlementOptions: [],
      areaOptions: [],
      settlementLoading: false,
      areaLoading: false
    }
  },
  computed: {
    coordinatesArray: {
      get() {
        return this.model.coordinates ? this.model.coordinates.split(',').map(c => c.replace(/ /g, '')) : []
      },
      set(val) {
        this.model.coordinates = val.length ? val.join(',') : ''
      }
    },
    ...mapState(MODULE_VILLAGES, {
      stateModel: state => state[SECTION_TYPE]
    })
  },
  created() {
    this.model = cloneDeep(this.stateModel)

    this.settlementLoading = true
    geoService
      .getSettlements()
      .then(result => {
        this.settlementOptions = result
      })
      .finally(() => {
        this.settlementLoading = false
      })

    this.areaLoading = true
    geoService
      .getAreas()
      .then(result => {
        this.areaOptions = result
      })
      .finally(() => {
        this.areaLoading = false
      })
  }
}
</script>
