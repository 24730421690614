<template>
  <form class="form" v-on="$listeners">
    <slot name="header"></slot>
    <slot></slot>
    <slot name="footer"></slot>
  </form>
</template>

<script>
export default {
  name: 'VForm'
}
</script>
