<template>
  <svg class="icon icon--avatar" width="14" height="17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.4 7.5v-.7V9h-.1c-2.2 0-4 2-4 4.2v2.6l.2.1h11l.2-.1v-2.6c0-2.3-1.8-4.2-4-4.2h0V7.5v-.7"
      stroke-width="1.1"
    />
    <!-- eslint-disable vue/max-len -->
    <path
      d="M4.2 13.5v2M9.8 13.5v2M4.9 9.2s1 .5 2.1.5 2.2-.5 2.2-.5M4.2 3.5s1.2 0 2.2-.3c1-.5 1.8-1.3 1.8-1.3M7.5 2.7l2.3 2"
      stroke-width="1.1"
      stroke-linecap="round"
    />
    <circle cx="7" cy="4.3" r="3.2" stroke-width="1.1" />
  </svg>
</template>

<script>
export default {
  name: 'VAvatar'
}
</script>
