<template>
  <match-media v-slot="{ mobile }">
    <fieldset-wrapper :model="model" :type="$options.SECTION_TYPE" :module="$options.MODULE_VILLAGES">
      <v-section class="village-edit-photos" :title="mobile ? 'ФОТОГРАФИИ' : ''">
        <validation-provider ref="provider" rules="required">
          <v-image-uploader-with-preview
            :photos="photos"
            @change="changePhotos"
            @add="addPhoto"
            @remove="removePhoto"
          />
        </validation-provider>
      </v-section>
    </fieldset-wrapper>
  </match-media>
</template>

<script>
import { MatchMedia } from 'vue-component-media-queries'
import FieldsetWrapper from '@/components/form/FieldsetWrapper.vue'
import VSection from '@/components/common/VSection.vue'
import VImageUploaderWithPreview from '@/components/common/VImageUploaderWithPreview.vue'
import { PHOTOS, MODULE_VILLAGES } from '@/store/modules/villages/villages.types'
import { cloneDeep } from '@/utils/common'
import { mapState } from 'vuex'

const SECTION_TYPE = PHOTOS

export default {
  name: 'VillageEditPhotos',
  components: { MatchMedia, FieldsetWrapper, VSection, VImageUploaderWithPreview },
  SECTION_TYPE,
  MODULE_VILLAGES,
  data() {
    return {
      model: {
        newVillageMedia: {},
        deletedVillageMedia: [],
        villageMediaOrders: {}
      },
      photos: []
    }
  },
  computed: {
    ...mapState(MODULE_VILLAGES, {
      statePhotos: state => state.photos,
      stateModel: state => state[SECTION_TYPE]
    })
  },
  watch: {
    photos(value) {
      this.changePhotoOrders()
      this.$refs.provider.validate(value)
    }
  },
  created() {
    this.photos = cloneDeep(this.statePhotos).sort((a, b) => a.myOrder - b.myOrder)
    this.model = cloneDeep(this.stateModel)
  },
  methods: {
    changePhotos(photos) {
      this.photos = photos
    },
    addPhoto(photo) {
      this.photos.push(photo)
      this.model.newVillageMedia[photo.id] = this.photos.length - 1
    },
    removePhoto(photo) {
      this.photos = this.photos.filter(p => p.id !== photo.id)

      if (this.isCustomPhoto(photo)) delete this.model.newVillageMedia[photo.id]
      else if (this.isVillagePhoto(photo)) this.model.deletedVillageMedia.push(photo.id)
    },
    changePhotoOrders() {
      const { villageMediaOrders, newVillageMedia } = this.photos.reduce(
        (acc, { id }, index) => {
          if (this.isCustomPhoto({ id })) acc.newVillageMedia[id] = index
          else if (this.isVillagePhoto({ id })) acc.villageMediaOrders[id] = index

          return acc
        },
        { villageMediaOrders: {}, newVillageMedia: {} }
      )
      this.model = { ...this.model, villageMediaOrders, newVillageMedia }
    },

    isCustomPhoto({ id }) {
      return this.model.newVillageMedia && `${id}` in this.model.newVillageMedia
    },
    isVillagePhoto({ id }) {
      return this.statePhotos && this.statePhotos.find(photo => photo.id === id)
    }
  }
}
</script>
