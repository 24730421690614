<template>
  <fieldset-wrapper :model="model" :type="$options.SECTION_TYPE" :module="$options.MODULE_VILLAGES">
    <v-section class="village-edit-files">
      <validation-provider ref="provider" rules="required">
        <v-file-uploader-with-preview :files="files" @add="addFile" @remove="removeFile" @rename="renameFile" />
      </validation-provider>
    </v-section>
  </fieldset-wrapper>
</template>

<script>
import FieldsetWrapper from '@/components/form/FieldsetWrapper.vue'
import VSection from '@/components/common/VSection.vue'
import VFileUploaderWithPreview from '@/components/common/VFileUploaderWithPreview.vue'

import { FILES, MODULE_VILLAGES } from '@/store/modules/villages/villages.types'
import { cloneDeep } from '@/utils/common'
import { mapState } from 'vuex'

const SECTION_TYPE = FILES

export default {
  name: 'VillagesEditFilesList',
  components: { FieldsetWrapper, VSection, VFileUploaderWithPreview },
  SECTION_TYPE,
  MODULE_VILLAGES,
  data() {
    return {
      model: {
        newVillageFiles: [],
        deletedVillageFiles: [],
        changedVillageFiles: []
      },
      files: []
    }
  },
  computed: {
    ...mapState(MODULE_VILLAGES, {
      stateFiles: state => state.files,
      stateModel: state => state[SECTION_TYPE]
    })
  },
  watch: {
    files(value) {
      this.$refs.provider.validate(value)
    }
  },
  created() {
    this.files = this.stateFiles.map(file => ({ ...file, name: `${file.name}.${file.source.split('.').pop()}` }))
    this.model = cloneDeep(this.stateModel)
  },
  methods: {
    addFile(file) {
      this.files.push(file)
      this.model.newVillageFiles[file.id] = this.files.length - 1
    },
    removeFile(file) {
      this.files = this.files.filter(f => f.id !== file.id)

      if (this.isCustomFile(file)) delete this.model.newVillageFiles[file.id]
      else if (this.isVillageFile(file)) this.model.deletedVillageFiles.push(file.id)
      delete this.model.changedVillageFiles[file.id]
    },
    renameFile(file, name) {
      // add originalFileName field to match id by file names
      // see services/http/villages create and update methods
      if (this.isCustomFile(file)) {
        this.model.changedVillageFiles[file.id] = {
          originalFileName: file.name,
          ...this.model.changedVillageFiles[file.id],
          name
        }
      } else if (this.isVillageFile(file)) {
        this.model.changedVillageFiles[file.id] = name
      }
      if (!name) delete this.model.changedVillageFiles[file.id]

      const extension = file.name.split('.').pop()
      const newFullName = `${name}.${extension}`
      this.files = this.files.map(f => (f.id === file.id ? { ...f, name: newFullName } : f))
    },

    isCustomFile({ id }) {
      return this.model.newVillageFiles && `${id}` in this.model.newVillageFiles
    },
    isVillageFile({ id }) {
      return this.stateFiles && this.stateFiles.find(file => file.id === id)
    }
  }
}
</script>
